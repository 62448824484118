<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              data-cy="load"
              class="load"
              rounded
              text
              color="darkGrey"
              dark
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
              Load
            </v-btn>
          </template>
          <span>Structures</span>
        </v-tooltip>
      </template>
      <v-card width="400" class="scroll" max-height="500">
        <v-list class="pl-5" v-if="dataSet.length > 0">
          <v-list-item
            data-cy="list_item"
            link
            v-for="(item, i) in dataSet"
            :key="i"
            @click="loadStructureToUI(item)"
          >
            <v-list-item-content>
              <v-list-item-title class="darkGrey--text" v-text="item.label">
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-sheet
                v-if="data != null && data._uuid === item._uuid"
                class="text text-caption font-weight-regular"
                >OPEN</v-sheet
              >
              <v-btn
                v-else
                data-cy="delete"
                icon
                @click.stop="askConfirmation(item)"
              >
                <v-icon color="grey lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list class="pl-5" v-else>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="darkGrey--text"
                v-text="'No structures created yet'"
              >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-card>
    </v-menu>

    <ConfirmDialog
      :visible="showConfirmDialog"
      @close="showConfirmDialog = false"
      @remove="deleteDataItem"
    />
  </div>
</template>

<script>
const ConfirmDialog = () => import('./ConfirmDialog')
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  components: {
    ConfirmDialog,
  },

  data: () => ({
    itemToDelete: {},
    showConfirmDialog: false,
    menu: false,
  }),

  props: {
    isDirty: Boolean,
  },

  methods: {
    ...mapActions('backend', ['delete', 'load', 'loadMultipleDataSets']),

    ...mapMutations('dynamicForms', ['createUserInputFromDataNode', 'set']),

    ...mapMutations('lattice', ['loadStructure']),

    ...mapMutations('backend', {
      setBackendStore: 'set',
      activateAlert: 'activateAlert',
      resetAlert: 'resetAlert',
    }),

    async loadStructureToUI(item) {
      if (this.$route.name.includes('lattice')) {
        this.loadStructure(item)
      } else {
        this.setBackendStore(['data', item])
        this.set(['edit', true])
        this.createUserInputFromDataNode(item)
      }
      this.menu = false
    },

    askConfirmation(item) {
      this.itemToDelete = item
      this.showConfirmDialog = true
    },

    async deleteDataItem() {
      try {
        this.showConfirmDialog = false
        await this.delete(['data', this.itemToDelete._uuid])
        let schemas = this.schemas.filter((e) => e.label === this.$route.name)
        await this.loadMultipleDataSets(schemas)
        this.activateAlert('success')
      } catch (e) {
        this.showConfirmDialog = false
        this.activateAlert('error')
      }
      window.setTimeout(() => {
        this.resetAlert()
      }, 7000)
    },
  },

  computed: {
    ...mapState('backend', {
      dataSet: (state) => state.dataSet,
      schemas: (state) => state.schemas,
      data: (state) => state.data,
    }),

    ...mapState('dynamicForms', {
      edit: (state) => state.edit,
    }),
  },

  async created() {
    if (this.schemas.length === 0) {
      await this.load(['schema'])
    }
    let schemas = this.schemas.filter((e) => e.label === this.$route.name.replaceAll('-', '_'))
    await this.loadMultipleDataSets(schemas)
  },
}
</script>

<style scoped>
.scroll {
  overflow-y: auto;
}
</style>
